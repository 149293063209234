<template>
  <div class="">
    <div v-if="auth.role == 'root'" class="search-box mb-2 d-inline-block">
      <div class="position-relative">
        <select
          class="form-control"
          v-model="lecturer_id"
          @change="fetchData"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
        >
          <option value="">{{ $t("view.lecturer_name") }}</option>
          <option
            v-for="(lecturer, idx) in lecturers"
            :key="idx"
            :value="lecturer.id"
          >
            {{ lecturer[$i18n.locale]["title"] }}
          </option>
        </select>
      </div>
    </div>

    <Loading v-if="pgLoading"> </Loading>

    <div
      v-else-if="!pgLoading && lecturer_id != ''"
      class="row justify-content-center align-items-center"
    >
      <div class="col-md-6 mt-5 bg-white p-4 rounded rounded-2">
        <form @submit.prevent="updateData">
          <h5 :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''">
            {{ $t("app.totalAmount") }}
          </h5>
          <input
            v-model="total"
            type="text"
            id="total"
            class="form-control mb-3"
            @input="validateDecimal(total)"
          />

          <h5 :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''">
            {{ $t("app.deservedAmount") }}
          </h5>
          <input
            v-model="deserved"
            type="text"
            id="deserved"
            class="form-control"
            @input="validateDecimal(deserved)"
          />
          <button
            type="submit"
            class="btn mt-3 btn-primary font-weight-semibold"
            :class="$i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''"
          >
            {{ $t("app.update") }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
  
  <script>
import iziToast from "izitoast";

export default {
  name: "Cards",
  props: ["refs"],
  components: {
    Loading: () => import("@/components/Loading.vue"),
  },
  data() {
    return {
      auth: {
        role: "",
        access_token: "",
      },
      pgLoading: true,
      items: {},
      total: 0,
      deserved: 0,
      lecturer_id: "",
      encrypt_id: "",
      lecturers: [],
    };
  },
  created() {
    // role && accessToken
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }

    // fetchData
    this.fetchData();

    if (this.auth.role == "root") {
      this.fetchLecturers();
    }
  },
  methods: {
    fetchData() {
      this.pgLoading = true;

      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${this.auth.access_token}`,
      };

      const options = {
        url: window.baseURL + "/" + this.$props.refs,
        method: "GET",
        data: {},
        params: {
          lecturer_id: this.lecturer_id,
        },
      };

      this.axios(options)
        .then((res) => {
          this.items = res.data.row;
          this.total = res.data.row.total_amount;
          this.deserved = res.data.row.deserved_amount;
          this.encrypt_id = res.data.row.encrypt_id;
          this.pgLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            console.log(" ");
          }
        })
        .finally(() => {
          this.pgLoading = false;
        });
    },

    fetchLecturers() {
      this.lecturerLoading = true;

      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${this.auth.access_token}`,
      };

      const options = {
        url: window.baseURL + "/lecturers",
        method: "GET",
        data: {},
        params: {
          status: "active",
          paginate: 100,
        },
      };

      this.axios(options)
        .then((res) => {
          this.lecturers = res.data.data.rows;
          //   console.log(this.lecturerLoading);
          this.lecturerLoading = false;
          //   console.log(this.lecturerLoading);
        })
        .catch((err) => {
          this.lecturerLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {
          this.lecturerLoading = false;
        });
    },

    validateDecimal(value) {
      this[value] = value.replace(/[^0-9.]/g, "");
    },

    updateData() {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/financialdues/" + this.encrypt_id,
        method: "PUT",
        data: {
          total_amount: this.total,
          deserved_amount: this.deserved,
        },
      };
      this.axios(options)
        .then(() => {
          this.fetchData(true);
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم التعديل بنجاح",
          });
        })
        .catch((err) => {
          this.fetchData(true);
          iziToast.error({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },
  },
};
</script>
  
  <style scoped>
/* Add your scoped styles here */
</style>